module.exports = [{
      plugin: require('../../node_modules/gatsby-theme-apollo/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"defaultIntegrations":false,"dsn":"https://4bd95becfbd249f3bf9f0063d0bcdf62@o269773.ingest.sentry.io/5613329"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"background_color":"#fefbf3","display":"minimal-ui","icon":"src/images/Culdesac_RoseStar_Color.png","name":"culdesac","short_name":"culdesac","start_url":"/","theme_color":"#ffffff","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"d2008358591dda1718fb9f5cae127d6a"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-breakpoints/gatsby-browser.js'),
      options: {"plugins":[],"queries":{"xxs":"(max-width: 321px)","xs":"(max-width: 400px)","sm":"(max-width: 640px)","md":"(max-width: 768px)","lg":"(max-width: 1024px)","xl":"(max-width: 1280px)","xxl":"(max-width: 1400px)","new-2xl":"(max-width: 1536px)","3xl":"(max-width: 1600px)"}},
    },{
      plugin: require('../../node_modules/gatsby-plugin-use-query-params/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
